import { Component, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { ConfigService } from './config';
import { Router, NavigationEnd } from '@angular/router';
import { UserService } from 'app/user/user.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ROUTES } from '../shared/sidebar/sidebar-routes.config';
import { Subscription } from 'rxjs';
import { Location } from "@angular/common";
import { filter } from 'rxjs/operators';


export class HelperService {
  screenAccess: any = {};
  dashAccess =  false;
    salesRepAccess = false;
    fileUploadAccess = false;
    reportsAccess = false;
    commissionAccess = false;
    subscription: Subscription;
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private router: Router,
    private _userService: UserService, 
    private _flashMessagesService: FlashMessagesService,
    private location: Location,
    ) {
      // Logic related to allowing access based on session.
      this._userService.checkUserSession().then((isSessionValid) => {
        if (!isSessionValid 
          && !this._userService.guesUserLinks.includes(this.location.path()) 
          && !this.location.path().includes('resetpassword')
        ) {
          this._flashMessagesService.show("Invalid Access!", { cssClass: 'alert-danger', timeout: 4000 });
          this.router.navigate(['/login']);
        } else if(isSessionValid) {
          const screenAccessArray = [];
          const UrlPathArray = ['', '/login'];
          this._userService.getMe().subscribe(
          (meData) => {
            if ( meData.data.Role.name === 'Staff' ) {
              this.screenAccess = meData.data.screenAccess;
              const screenAccessList = this.screenAccess.split(',');
              this.dashAccess = screenAccessList.filter(
                record => (record.toLowerCase() === 'dashboard')
              )
              this.salesRepAccess = screenAccessList.filter(
                record => (record.toLowerCase() === 'salespeople-list')
              )
              this.fileUploadAccess = screenAccessList.filter(
                record => (record.toLowerCase() === 'uploadfile')
              )
              this.commissionAccess = screenAccessList.filter(
                record => (record.toLowerCase() === 'commission-list')
              )
              this.reportsAccess = screenAccessList.filter(
                record => (record.toLowerCase() === 'reports')
              )
              ROUTES.forEach(async (route) => {
                const routes = route.path.replace('/', '');
                const screensAccessList = routes.replace('/', '-');
                if ( this.dashAccess[0] === screensAccessList ) {
                  screenAccessArray.push(route);
                  UrlPathArray.push(route.path);
                }
                if ( this.salesRepAccess[0] === screensAccessList ) {
                  screenAccessArray.push(route);
                  UrlPathArray.push(route.path);
                }
                if ( this.fileUploadAccess[0] === screensAccessList ) {
                  screenAccessArray.push(route);
                  UrlPathArray.push(route.path);
                }
                if ( this.commissionAccess[0] === screensAccessList ) {
                  screenAccessArray.push(route);
                  UrlPathArray.push(route.path);
                }
                if ( this.reportsAccess[0] === screensAccessList ) {
                  screenAccessArray.push(route);
                  UrlPathArray.push(route.path);
                }
                if (screensAccessList === 'regions' 
                  || screensAccessList === 'territories' 
                  || screensAccessList === 'hospitals'
                  || screensAccessList === 'procedures'
                  || screensAccessList === 'surgeons'
                ) {
                  screenAccessArray.push(route);
                  UrlPathArray.push(route.path);
                }
              });
              var locationPath = this.location.path();
              if(locationPath.includes('/uploadfile/mapdata')) {
                locationPath = '/uploadfile';
              } 
              if (!UrlPathArray.includes(locationPath)) {
                this._flashMessagesService.show('Invalid Access!', { cssClass: 'alert-danger', timeout: 4000 });
                this.router.navigate([screenAccessArray[0].path]);
              } /*else {
                this.router.navigate([screenAccessArray[0].path]);
              }*/
            }            
          });
        }
      });
      this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));
  }
  getCurrentDate() {
    return moment().format('YYYY-MM-DD');
  }
  getCurrentMonth() {
    const startDate = moment().startOf('month').format('Y-MM-DD');
    const MiddleDate = moment(startDate).add(14, 'days').format('Y-MM-DD');
    const currentDate = moment().format('Y-MM-DD');
    let currentMonthOfYear;
    if(this.router.url === '/uploadfile') {
      // one month ahead than other screens...
      if ( currentDate <= MiddleDate ) {
        currentMonthOfYear = moment().subtract(1, 'month').format('MMMM-YYYY');
      } else {
        currentMonthOfYear = moment().format('MMMM-YYYY');
      }
    } else {
      if ( currentDate <= MiddleDate ) {
        currentMonthOfYear = moment().subtract(2, 'month').format('MMMM-YYYY');
      } else {
        currentMonthOfYear = moment().subtract(1, 'month').format('MMMM-YYYY');
      }
    }
    return currentMonthOfYear;
  }

  getFormattedMonthYear(monthYear) {
    // This function created as solution to "value provided is not in a recognized RFC2822 or ISO format" warning
    return moment(this.getDateFromMonth(monthYear, 'startDate'));
  }
  getCurrentYear() {
    const startDate = moment().startOf('month').format('Y-MM-DD');
    const MiddleDate = moment(startDate).add(14, 'days').format('Y-MM-DD');
    const NextMiddleDate = moment(startDate).add(14, 'days').add(1, 'month').format('Y-MM-DD');
    const currentDate = moment().format('Y-MM-DD');
    const month = moment().format("MMMM");
    if ((month == 'January' && (MiddleDate >= currentDate || (NextMiddleDate >= currentDate && MiddleDate < currentDate)))) {
      return moment().subtract(1, 'year').format('YYYY');
    } else if (month == 'February' && MiddleDate >= currentDate) {
      return moment().subtract(1, 'year').format('YYYY');
    } else {
      return moment().format('YYYY');
    }
  }
  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }
  getDateFromMonth = function (monthValue, type) {
    const monthValueSplitList = monthValue.split("-");
    const month = moment().month(monthValueSplitList[0]).format("M");
    const year = monthValueSplitList[1];
    var startDate = moment([year, parseInt(month) - 1]);
    const endDate = moment(startDate).endOf('month');
    if (type == 'startDate') {
      return startDate.format("Y-MM-DD");
    } else if (type == 'endDate') {
      return endDate.format("Y-MM-DD");
    } else if (type == 'middleDate') {
      return endDate.format("Y-MM-15");
    }
  }
  getMonthList(pastMonthLimit, futureMonthLimit) {
    let monthList = [];
    for (let loopIndex = pastMonthLimit; loopIndex >= 1; loopIndex--) {
      const month = moment().subtract(loopIndex, 'M').format('MMMM-YYYY');
      monthList.push(month);
    }
    monthList.push(this.getCurrentMonth());
    for (let loopIndex = 1; loopIndex <= futureMonthLimit; loopIndex++) {
      const month = moment().add(loopIndex, 'M').format('MMMM-YYYY');
      monthList.push(month);
    }
    return monthList;
  }

  getMonthsListOfProcessedData() {
    return this.http.get(`${this.configService.apiUrl}/uploadprocess/getMonthsListOfProcessedData`);
  }
  convertToNumber(strValue) {
    return parseFloat(strValue);
  }
  isNegativeValue(amount) {
    return amount.toString().includes('-');
  }  
  getCompanyName() {
    return  localStorage.getItem('cn');    
  }
  getFooterTextValue() {
    if(this.getCompanyName() === 'raridon') {
      return 'Raridon';
    } else if(this.getCompanyName() === 'lcortho') {
      return 'Lcortho-sales';
    } else if(this.getCompanyName() === 'A1A') {
      return 'A1A';
    } else if(this.getCompanyName() === 'inmotionortho') {
      return 'In Motion Ortho,  Inc';
    } else if(this.getCompanyName() === 'medicalventures') {
      return 'Medical Ventures';
    } else if(this.getCompanyName() === 'simpson') {
      return 'Simpson-sales';
    } else if(this.getCompanyName() === 'mvisidelines') {
      return 'MVIsidelines-sales';
    } else if(this.getCompanyName() === 'DJD') {
      return 'DJD Medical Inc.';
    }  
  }
  getTitleTextValue() {
    if(this.getCompanyName() === 'raridon') {
      return 'Raridon & Associates';
    } else if(this.getCompanyName() === 'lcortho') {
      return 'Lcortho Admin';
    } else if(this.getCompanyName() === 'A1A') {
      return 'A1A';
    } else if(this.getCompanyName() === 'inmotionortho') {
      return 'In Motion Ortho';
    } else if(this.getCompanyName() === 'medicalventures') {
      return 'Medical Ventures';
    } else if(this.getCompanyName() === 'simpson') {
      return 'Simpson-sales';
    } else if(this.getCompanyName() === 'mvisidelines') {
      return 'MVIsidelines-sales';
    } else if(this.getCompanyName() === 'DJD') {
      return 'DJD Medical Inc.';
    } 
  }
}